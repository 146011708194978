import { logError } from "index"
import { action, computed, observable, runInAction, set } from "mobx"
import { Subject } from "rxjs"
import { filter } from "rxjs/operators"
import {
  EXPIRE_DEV_SESSION,
  FAKE_LOGIN,
  IGNORE_PROFILE_THEME,
  SESSION_REFRESH_INTERVAL
} from "@consts/config"
import {
  getInfo,
  logout as requestLogout,
  requestJWT,
  requestUserSession,
  requestTwoFactor,
  validateTwoFactorToken
} from "@api/auth"
import { API_ERROR, errorEvents } from "@api/http"
import {
  AuthErrorCode,
  ChaseConfig,
  JWTErrorDescription,
  JWTResponse,
  SessionInfo,
  UiPermissions
} from "@api/model/commonModel"
import React from "react"
import { MobXProviderContext } from "mobx-react"
import {
  AccessAnalytics,
  AccessCreateProperty,
  AccessPermissions,
  AccessPlain,
  AccessProperties,
  AccessWithReadOnly
} from "@api/model/accessModel"
import { ProfileData } from "@api/model/webformModel"
import isOneOf from "@utils/isOneOf"
import { getConfig } from "@api/config"
import { getProfile, updateProfile } from "@api/profile"
import { getCountryCodes } from "@api/common-entities"
import { v4 as uuid } from "uuid"
import { authState } from "./authState"

export type LoginErrorReason = AuthErrorCode

export const popupSubject = new Subject<any>()
export const popupEvent = popupSubject.asObservable()

export const accountSubject = new Subject<{ name: string; id: string }>()
export const accountEvent = accountSubject.asObservable()

export const loginSubject = new Subject()
export const loginEvent = loginSubject.asObservable()

export const logoutSubject = new Subject()
export const logoutEvent = logoutSubject.asObservable()

export enum AUTH_STEP {
  LOGIN,
  TWOFACTOR_SMS,
  TWOFACTOR_EMAIL
}

// 1. /info -> profile + config -> authenticate
// 2. /login -> 2fa -> verify -> info + profile + config -> authenticate
// 3. /login -> profile + config -> authenticate

export class AppState {
  static NAME = "appState"

  @observable access = { mobileAccess: true, portalAccess: true }
  @observable config: ChaseConfig = {
    jobAmend: {
      minBeforeAmendMinutes: 0,
      minBeforeJobDateMinutes: 0
    }
  }
  @observable profile?: ProfileData
  @observable authStep = AUTH_STEP.LOGIN
  @observable loginErrorReason?: LoginErrorReason
  @observable initialized = false
  @observable authenticated = false
  @observable hasLoggedOut = false
  @observable accessPermissions?: AccessPermissions
  @observable loggingIn = false
  @observable redirecting = false
  @observable singningUp = false
  @observable uiPermissions?: UiPermissions
  @observable fullName?: string
  @observable phone?: string
  @observable username?: string
  @observable accountNumbers: (number | string)[] = [""]
  @observable hasMobileAccess = false
  @observable hasSmartAccess = false
  @observable seenBadges = {}
  @observable whiteLabelProfile?: string

  @observable theme = { accent: "#fdc", primary: "#faa200" }

  @observable allowedPhoneCodes = ["GB"]

  @observable bigSpin = false

  @observable
  sessionPreserveInterval: NodeJS.Timeout | null = null

  @computed get componentAccess() {
    const ap = this.accessPermissions

    return {
      general: ap?.general === AccessPlain.ENABLED,
      navbar: {
        accessGroups: ap?.accessGroups !== AccessWithReadOnly.DISABLED,
        accounts: ap?.accounts !== AccessWithReadOnly.DISABLED,
        accountsGroup:
          ap?.accounts !== AccessWithReadOnly.DISABLED ||
          ap?.invoices !== AccessPlain.DISABLED,
        addProperty: ap?.newProperty !== AccessCreateProperty.DISABLED,
        analytics: ap?.analytics !== AccessAnalytics.DISABLED,
        cameras: ap?.cameras !== AccessPlain.DISABLED,
        connect: ap?.connect !== AccessPlain.DISABLED,
        dailyOccurrences: ap?.dailyOccurrences !== AccessPlain.DISABLED,
        deviceDetails: ap?.deviceDetails !== AccessWithReadOnly.DISABLED,
        deviceUsers: ap?.deviceUsers !== AccessWithReadOnly.DISABLED,
        hutten: ap?.hutten !== AccessPlain.DISABLED,
        invoices: ap?.invoices !== AccessPlain.DISABLED,
        jobsGroup:
          ap?.requestJob !== AccessPlain.DISABLED ||
          ap?.patrolPackage !== AccessPlain.DISABLED ||
          ap?.viewJobs !== AccessWithReadOnly.DISABLED,
        occurrenceSearch: ap?.occurrenceSearch !== AccessPlain.DISABLED,
        patrolPackage: ap?.patrolPackage !== AccessPlain.DISABLED,
        profile: ap?.editProfile !== AccessPlain.DISABLED,
        properties: ap?.properties !== AccessProperties.DISABLED,
        propertiesGroup:
          ap?.properties !== AccessProperties.DISABLED ||
          ap?.schedules !== AccessWithReadOnly.DISABLED,
        propertiesMobilisation:
          ap?.propertiesMobilisation !== AccessPlain.DISABLED,
        propertyCalendar: ap?.propertyCalendar !== AccessPlain.DISABLED,
        requestJob: ap?.requestJob !== AccessPlain.DISABLED,
        riskRegister: ap?.riskRegister !== AccessPlain.DISABLED,
        schedules: ap?.schedules !== AccessWithReadOnly.DISABLED,
        settingsGroup:
          ap?.manageUsers !== AccessWithReadOnly.DISABLED ||
          ap?.editProfile !== AccessPlain.DISABLED,
        smartDevicesForm: ap?.smartDevices !== AccessPlain.DISABLED,
        smartDevicesGroup:
          ap?.deviceUsers !== AccessWithReadOnly.DISABLED ||
          ap?.deviceDetails !== AccessWithReadOnly.DISABLED ||
          ap?.accessGroups !== AccessWithReadOnly.DISABLED,
        sraDashboard: ap?.sraDashboard !== AccessPlain.DISABLED,
        support: ap?.support !== AccessPlain.DISABLED,
        uploadFile: ap?.uploadFile !== AccessPlain.DISABLED,
        users: ap?.manageUsers !== AccessWithReadOnly.DISABLED,
        viewJobs: ap?.viewJobs !== AccessWithReadOnly.DISABLED,
        whatsNew: ap?.whatsNew !== AccessPlain.DISABLED
      },
      pages: {
        accessGroups: {
          controls: ap?.accessGroups === AccessWithReadOnly.ENABLED
        },
        accounts: { controls: ap?.accounts === AccessWithReadOnly.ENABLED },
        addProperty: {
          adhocPrompt: isOneOf(ap?.newProperty, [
            AccessCreateProperty.ENABLED_EXTERNAL_FULL_AND_ADHOC_WITH_DETAILS,
            AccessCreateProperty.ENABLED_FULL_AND_ADHOC_WITH_DETAILS,
            AccessCreateProperty.ENABLED_FULL_AND_ADHOC
          ]),
          surveys: isOneOf(ap?.newProperty, [
            AccessCreateProperty.FULL_ADHOC_AIS,
            AccessCreateProperty.ENABLED_EXTERNAL_FULL_AND_ADHOC_WITH_DETAILS,
            AccessCreateProperty.ENABLED_FULL_AND_ADHOC_WITH_DETAILS
          ])
        },
        analytics: {
          selector: ap?.analytics === AccessAnalytics.BESPOKE
        },
        booking: {
          pricing: ap?.invoices !== AccessPlain.DISABLED
        },
        connect: {
          controls: ap?.connect === AccessPlain.ENABLED
        },
        deviceUsers: {
          controls: ap?.deviceUsers === AccessWithReadOnly.ENABLED
        },
        manageUsers: {
          edit: ap?.manageUsers === AccessWithReadOnly.ENABLED
        },
        properties: {
          aiEdit: ap?.properties === AccessProperties.ENABLED,
          assessmentInstructions: isOneOf(ap?.properties, [
            AccessProperties.ENABLED,
            AccessProperties.READ_ONLY
          ]),
          edit: isOneOf(ap?.properties, [
            AccessProperties.ENABLED,
            AccessProperties.AI_HIDDEN
          ])
        },
        schedules: {
          edit: ap?.schedules === AccessWithReadOnly.ENABLED
        },
        viewJobs: {
          jobMenu: ap?.viewJobs === AccessWithReadOnly.ENABLED
        }
      }
    }
  }

  toggleBigSpin = (bool: boolean) => {
    this.bigSpin = bool
  }

  loadPhoneCodes = async () => {
    const res = await getCountryCodes()
    if (res.error) {
      popupSubject.next(`Failed to get country codes: ${res.data}`)
      return
    }
    const countries: string[] = []
    for (const country of res as {
      twoDigitIso: "string"
      code: "string"
    }[]) {
      countries.push(country.twoDigitIso)
    }
    this.allowedPhoneCodes = countries
  }

  constructor() {
    try {
      this.seenBadges = JSON.parse(localStorage.getItem("seenBadges") || "{}")
    } catch (error) {
      console.warn("No local storage access")
    }
    // load phone codes after login
    loginSubject.subscribe(() => {
      this.loadPhoneCodes()
    })
    if (errorEvents) {
      errorEvents
        .pipe(filter((e) => e === API_ERROR.FORBIDDEN))
        .subscribe(() => {
          this.expireSession()
        })
    }
  }

  expireSession = () => {
    if (
      !this.authenticated ||
      (process.env.NODE_ENV === "development" && !EXPIRE_DEV_SESSION)
    ) {
      return
    }
    this.loginErrorReason = AuthErrorCode.SESSION_EXPIRED
    this.deauthenticate()
  }

  initSessionPreservation = () => {
    this.sessionPreserveInterval = setInterval(async () => {
      try {
        const res = await getInfo()
        if (res.code === 401) {
          this.expireSession()
        } else if (res.error || "code" in res) {
          throw Error(res.data)
        }
      } catch (error) {
        console.error("Couldn't refresh session", error)
      }
    }, SESSION_REFRESH_INTERVAL)
  }

  @action saveInfo = (info: SessionInfo) => {
    this.uiPermissions = info.uiPermissions
    this.uiPermissions = info.uiPermissions
    this.fullName = info.fullName
    this.phone = info.phone
    this.username = info.username
    this.accountNumbers = info.accountNumbers.length
      ? info.accountNumbers
      : [""]
    if (info.deviceKey) {
      localStorage.setItem("KHC_DEVICE_KEY", info.deviceKey)
    }
    this.whiteLabelProfile = info.uiPermissions.whiteLabelProfile
  }

  @action saveLoginData = (
    info?: SessionInfo,
    profile?: ProfileData,
    config?: any
  ) => {
    if (config) {
      this.config = config
    }
    if (info) {
      this.uiPermissions = info.uiPermissions
      this.accessPermissions = info.permissions
      this.accountNumbers = info.accountNumbers.length
        ? info.accountNumbers
        : [""]
      this.username = info.username

      if (info.permissions["keycloak"] === "ENABLED") {
        localStorage.setItem("KEYCLOAK_ENABLED", "true")
      } else {
        localStorage.removeItem("KEYCLOAK_ENABLED")
      }

      if (info.deviceKey) {
        localStorage.setItem("KHC_DEVICE_KEY", info.deviceKey)
      }

      this.whiteLabelProfile = info.uiPermissions.whiteLabelProfile
    }
    if (profile) {
      this.username = profile.email
      this.profile = profile
      this.fullName =
        (profile.firstName ? profile.firstName : "") +
        (profile.lastName
          ? profile.firstName
            ? ` ${profile.lastName}`
            : profile.lastName
          : "")
      this.phone = profile.phone

      this.hasMobileAccess = profile.hasMobileAccess
      this.hasSmartAccess = profile.hasSmartAccess
    }
  }

  @action
  initialize = async () => {
    try {
      const info = await getInfo()
      if (info.error || "code" in info) {
        throw Error(info.data)
      }
      const [config, profile] = await Promise.all([getConfig(), getProfile()])
      if (config.error) {
        throw Error(config.data)
      }
      if (profile.error) {
        throw Error(profile.data)
      }
      this.saveLoginData(info.data, profile.data, config.data)
      runInAction(() => {
        this.authenticate()
      })
      return true
    } catch (error) {
      this.authenticated = FAKE_LOGIN
    } finally {
      this.initialized = true
    }
  }

  @action
  authenticate = async () => {
    if (
      !IGNORE_PROFILE_THEME &&
      this.whiteLabelProfile &&
      this.whiteLabelProfile !== window.theme?.urlName
    ) {
      const split = window.location.pathname.split("/")
      if (split.length > 1 && split[1] === "home") {
        window.location.pathname = `/home/${this.whiteLabelProfile}/`
      } else {
        window.location.pathname = `/${this.whiteLabelProfile}`
        this.redirecting = true
      }
      return
    } else if (!this.whiteLabelProfile && window.theme) {
      const split = window.location.pathname.split("/")
      window.location.pathname =
        split.length > 1 && split[1] === "home" ? "/home" : "/"
      this.redirecting = true
      return
    }
    this.authenticated = true
    this.initSessionPreservation()
    loginSubject.next()
    window.dataLayer.push({ user_id: this.username })
    window.dataLayer.push({ event: "User ID set", user_id: this.username })
  }

  // it gets session info and marks sessions as authenticated
  // normally it called after auth0 successful login
  @action
  async authenticateInternally() {
    try {
      const res = await requestUserSession()

      if (res.error) {
        this.loginErrorReason = AuthErrorCode.TOKEN_LOGIN_ERROR
        throw Error(res.data)
      }
      const [profile, config] = await Promise.all([getProfile(), getConfig()])
      if (profile.error) {
        throw new Error(profile.data)
      }
      if (config.error) {
        throw new Error(config.data)
      }
      const infoData = res.data
      this.saveLoginData(infoData, profile.data)
      this.authenticate()
    } catch (error) {
      logError(error)
    }
  }

  @action
  login = async (l: string, password: string, tokenPayload?: JWTResponse) => {
    try {
      runInAction(() => {
        this.loginErrorReason = undefined
        this.loggingIn = true
      })

      // If JWT is not received from V2 in payload, call JWT end point to get token.
      let cachedDeviceKey = localStorage.getItem("KHC_DEVICE_KEY")
      if (!cachedDeviceKey) {
        cachedDeviceKey = uuid()
        localStorage.setItem("KHC_DEVICE_KEY", cachedDeviceKey)
      }

      if (!tokenPayload) {
        const jwtResult = (await requestJWT(
          l,
          password,
          cachedDeviceKey
        )) as JWTResponse
        if (!jwtResult.error) {
          localStorage.removeItem("JWT_KC_MODE")
          localStorage.setItem("JWT_ACCESS", jwtResult.access_token)
          localStorage.setItem("JWT_REFRESH", jwtResult.refresh_token)
          localStorage.setItem(
            "JWT_EXPIRY",
            String(Date.now() + jwtResult.expires_in * 1000)
          )
        } else {
          if (jwtResult.error_description === JWTErrorDescription.USER_LOCKED) {
            this.loginErrorReason = AuthErrorCode.USER_LOCKED
          } else if (
            jwtResult.error_description === JWTErrorDescription.ACCESS_DENIED
          ) {
            this.loginErrorReason = AuthErrorCode.ACCESS_DENIED
          } else {
            this.loginErrorReason = AuthErrorCode.TOKEN_LOGIN_ERROR
          }
          throw Error(jwtResult.error)
        }
      } else {
        localStorage.removeItem("JWT_KC_MODE")
        localStorage.setItem("JWT_ACCESS", tokenPayload.access_token)
        localStorage.setItem("JWT_REFRESH", tokenPayload.refresh_token)
        localStorage.setItem(
          "JWT_EXPIRY",
          String(Date.now() + tokenPayload.expires_in * 1000)
        )
      }
      const res = await requestUserSession()
      if (res.error) {
        this.loginErrorReason =
          AuthErrorCode.TOKEN_LOGIN_ERROR || AuthErrorCode.ACCESS_DENIED
        throw Error(res.data)
      }
      const infoData = res.data
      if (infoData.uiPermissions.twoFactorType) {
        this.saveLoginData(infoData)
        return infoData.uiPermissions.twoFactorType
      }
      const [profile, config] = await Promise.all([getProfile(), getConfig()])
      if (profile.error) {
        throw new Error(profile.data)
      }
      if (config.error) {
        throw new Error(config.data)
      }
      this.saveLoginData(infoData, profile.data)
      this.authenticate()
      return "NO_2FA"
    } catch (error) {
      logError(error)
    } finally {
      this.loggingIn = false
    }
  }

  @action
  logout = async () => {
    try {
      this.toggleBigSpin(true)
      // if authenticated via auth0, logout
      if (authState.isAuthenticated) {
        await authState.logout()
      } else {
        await requestLogout()
      }
      this.deauthenticate()
    } catch (error) {
      logError(error)
    } finally {
      this.toggleBigSpin(false)
    }
  }

  @action
  deauthenticate = () => {
    this.clearSessionPreserveInterval()
    this.hasLoggedOut = true
    this.authenticated = false
    this.uiPermissions = undefined
    localStorage.removeItem("JWT_KC_MODE")
    localStorage.removeItem("JWT_EXPIRY")
    localStorage.removeItem("JWT_ACCESS")
    localStorage.removeItem("JWT_REFRESH")
    localStorage.removeItem("KHC_DARK_THEME")
    localStorage.removeItem("KHC_2FA_TAB_OPENED")
    localStorage.removeItem("BOOKING_QUERY_PARAMS")
    localStorage.removeItem("seenBadges")
    logoutSubject.next()
  }

  @action
  logoutInactive = () => {
    if (
      !this.authenticated ||
      (process.env.NODE_ENV === "development" && !EXPIRE_DEV_SESSION)
    ) {
      return
    }
    this.loginErrorReason = AuthErrorCode.INACTIVITY
    this.deauthenticate()
  }

  @action
  clearSessionPreserveInterval = () => {
    if (this.sessionPreserveInterval) {
      clearInterval(this.sessionPreserveInterval)
    }
  }

  @action
  hideNewBadge = (badgeId: string) => {
    set(this.seenBadges, badgeId, true)
    localStorage.setItem("seenBadges", JSON.stringify(this.seenBadges))
  }

  get isBillingAvailable(): boolean {
    return this.accessPermissions?.invoices === AccessPlain.ENABLED
  }

  @computed get isEtaAllowed(): boolean {
    return !!this.uiPermissions && !!this.uiPermissions.etaAllowed
  }

  @computed get isServiceTracAvailable(): boolean {
    return !!this.uiPermissions && this.uiPermissions.serviceTracId
  }

  onTwoFactorVerified = async () => {
    try {
      const info = await getInfo()
      if (info.error) {
        throw Error(info.data)
      }
      const [profile, config] = await Promise.all([getProfile(), getConfig()])
      if (profile.error) {
        throw Error(profile.data)
      }
      if (config.error) {
        throw Error(config.data)
      }
      this.saveLoginData(info.data, profile.data, config.data)
      return true
    } catch (error) {
      return false
    }
  }

  @action
  reacquireProfile = async () => {
    try {
      const res = await getProfile()
      if (res.error) {
        throw Error(res.data)
      }
      this.saveLoginData(undefined, res.data)
      return true
    } catch (error) {
      logError(error, "Couldn't acquire profile data")
      return false
    }
  }

  updateProfile = async (data: ProfileData) => {
    try {
      const res = await updateProfile(data)
      if (res.error) {
        throw Error(res.data)
      }
      runInAction(() => {
        this.profile = data
        this.username = data.email
        this.fullName =
          (data.firstName ? data.firstName : "") +
          (data.lastName
            ? data.firstName
              ? ` ${data.lastName}`
              : data.lastName
            : "")
        this.phone = data.phone
        popupSubject.next("Profile updated!")
      })
      return true
    } catch (error) {
      logError(error, "Couldn't update profile")
    }
  }

  validateTwoFactorToken = async (token: string, method: "sms" | "email") => {
    try {
      const res = await validateTwoFactorToken(token, method)
      if (res.error) {
        throw Error(res.data)
      }
      return { error: null }
    } catch (error: any) {
      logError(error)
      return { error: error.message }
    }
  }

  requestTwoFactor = async (method: "sms" | "email") => {
    try {
      const res = await requestTwoFactor(method)
      if (res.error) {
        throw Error(res.data)
      }
      return true
    } catch (error) {
      logError(error, "Couldn't request two-factor verification")
      return false
    }
  }
}

export const appState = new AppState()

export interface AppStateObserverProps {
  // [AppState.NAME]?: AppState;
  appState?: AppState
}

export function useAppState(): AppState {
  return React.useContext(MobXProviderContext).appState
}
