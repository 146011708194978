export enum AccessPlain {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED"
}

export enum AccessWithReadOnly {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  READ_ONLY = "READ_ONLY"
}

export enum AccessCreateProperty {
  // Full mobilization only enabled - AIs visible
  FULL_ADHOC_AIS = "ENABLED_WITH_DETAILS",

  // Full mobilization only enabled - AIs hidden
  FULL_ADHOC = "ENABLED",

  // External only and Ad-hoc mobilization enabled - AIs visible
  ENABLED_EXTERNAL_FULL_AND_ADHOC_WITH_DETAILS = "ENABLED_EXTERNAL_FULL_AND_ADHOC_WITH_DETAILS",

  // Full and Ad-hoc mobilization enabled - AIs visible
  ENABLED_FULL_AND_ADHOC_WITH_DETAILS = "ENABLED_FULL_AND_ADHOC_WITH_DETAILS",

  // Full and Ad-hoc mobilization enabled - AIs hidden
  ENABLED_FULL_AND_ADHOC = "ENABLED_FULL_AND_ADHOC",

  // Disabled
  DISABLED = "DISABLED"
}

export enum AccessProperties {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  READ_ONLY = "READ_ONLY",
  AI_HIDDEN = "AI_HIDDEN",
  READ_ONLY_AI_HIDDEN = "READ_ONLY_AI_HIDDEN"
}

export enum AccessAnalytics {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  BESPOKE = "BESPOKE"
}

export type AccessLevelCode =
  | AccessPlain
  | AccessAnalytics
  | AccessProperties
  | AccessWithReadOnly
  | AccessAnalytics

export enum AccessComponent {
  accessGroups = "accessGroups",
  accounts = "accounts",
  analytics = "analytics",
  cameras = "cameras",
  cancelProperty = "cancelProperty",
  connect = "connect",
  dailyOccurrences = "dailyOccurrences",
  deviceDetails = "deviceDetails",
  deviceUsers = "deviceUsers",
  editProfile = "editProfile",
  general = "general",
  hutten = "hutten",
  invoices = "invoices",
  keycloak = "keycloak",
  liveLocation = "liveLocation",
  manageUsers = "manageUsers",
  newProperty = "newProperty",
  occurrenceSearch = "occurrenceSearch",
  patrolPackage = "patrolPackage",
  properties = "properties",
  propertiesMobilisation = "propertiesMobilisation",
  propertyCalendar = "propertyCalendar",
  requestJob = "requestJob",
  riskRegister = "riskRegister",
  schedules = "schedules",
  smartDevices = "smartDevices",
  sraDashboard = "sraDashboard",
  support = "support",
  uploadFile = "uploadFile",
  viewJobs = "viewJobs",
  whatsNew = "whatsNew"
}

export const PageRedirectOrder: AccessComponent[] = [
  AccessComponent.requestJob,
  AccessComponent.viewJobs,
  AccessComponent.properties,
  AccessComponent.schedules,
  AccessComponent.accounts,
  AccessComponent.invoices,
  AccessComponent.smartDevices,
  AccessComponent.deviceUsers,
  AccessComponent.deviceDetails,
  AccessComponent.accessGroups,
  AccessComponent.cameras,
  AccessComponent.analytics,
  AccessComponent.manageUsers,
  AccessComponent.uploadFile
]

export interface AccessPermissions {
  [AccessComponent.accessGroups]: AccessWithReadOnly
  [AccessComponent.accounts]: AccessWithReadOnly
  [AccessComponent.analytics]: AccessAnalytics
  [AccessComponent.cameras]: AccessPlain
  [AccessComponent.cancelProperty]: AccessPlain
  [AccessComponent.connect]: AccessPlain
  [AccessComponent.dailyOccurrences]: AccessPlain
  [AccessComponent.deviceDetails]: AccessWithReadOnly
  [AccessComponent.deviceUsers]: AccessWithReadOnly
  [AccessComponent.editProfile]: AccessPlain
  [AccessComponent.general]: AccessPlain
  [AccessComponent.hutten]: AccessPlain
  [AccessComponent.invoices]: AccessPlain
  [AccessComponent.keycloak]: AccessPlain
  [AccessComponent.liveLocation]: AccessPlain
  [AccessComponent.manageUsers]: AccessWithReadOnly
  [AccessComponent.newProperty]: AccessCreateProperty
  [AccessComponent.occurrenceSearch]: AccessPlain
  [AccessComponent.patrolPackage]: AccessPlain
  [AccessComponent.properties]: AccessProperties
  [AccessComponent.propertiesMobilisation]: AccessPlain
  [AccessComponent.propertyCalendar]: AccessPlain
  [AccessComponent.requestJob]: AccessPlain
  [AccessComponent.riskRegister]: AccessPlain
  [AccessComponent.schedules]: AccessWithReadOnly
  [AccessComponent.smartDevices]: AccessPlain
  [AccessComponent.sraDashboard]: AccessPlain
  [AccessComponent.support]: AccessPlain
  [AccessComponent.uploadFile]: AccessPlain
  [AccessComponent.viewJobs]: AccessWithReadOnly
  [AccessComponent.whatsNew]: AccessPlain
}

export interface SystemAccessPermission {
  code: AccessComponent
  levels: AccessLevel[]
  name: string
}

export interface AccessLevel {
  name: string
  code: AccessLevelCode
  priority: number
}
